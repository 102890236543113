import Box from '@mui/material/Box';
import { memo } from '../../../util/memo';
import { ImageOptimized } from '../../image/ImageOptimized';
import { ViewerCountDynamic } from '../../livestream/mux/ViewerCountDynamic';
import { useMemo } from 'react';

export type TournamentCardThumbnailProps = {
  imgUrl: string;
  sponsorCount: number;
  livestreamId?: string;
  playbackId?: string;
};

const TournamentCardThumbnailUnmemoized = ({
  imgUrl,
  sponsorCount,
  livestreamId,
  playbackId,
}: TournamentCardThumbnailProps) => {
  const viewerCount = useMemo(() => {
    return (
      !!livestreamId && (
        <ViewerCountDynamic
          livestreamId={livestreamId}
          playbackId={playbackId}
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
      )
    );
  }, [livestreamId, playbackId]);

  return (
    <Box position="relative">
      <ImageOptimized
        src={imgUrl}
        height={94}
        width={!sponsorCount ? 274 : 240}
        style={{ borderRadius: '5px' }}
      />
      {viewerCount}
    </Box>
  );
};

export const TournamentCardThumbnail = memo(TournamentCardThumbnailUnmemoized);
