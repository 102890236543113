import { FC, useCallback, useMemo, useState } from 'react';
import { memo } from '../../util/memo';
import {
  EventHit,
  EventsCalendar,
  EventsCalendarProps,
} from './catalog-wrappers/EventsCalendar';
import {
  AlgoliaLayoutBidirectional,
  AlgoliaLayoutBidirectionalProps,
} from './AlgoliaLayoutBidirectional';
import { CalendarDaily, CalendarDailyProps } from './catalogs/CalendarDaily';
import { UseConfigureProps } from 'react-instantsearch';
import { dateOnlyMillis } from '../../../functions/src/util/algolia/eventsTransform';
import { OrNode } from '../../../functions/src/types/Hit';
import { useMobile } from '../../hooks/useMobile';
import { useUtcOffset } from '../../hooks/useUtcOffset';
import { UTC_ALGOLIA_INDEX_NAME_MAP } from '../../config/algolia/algoliaIndexMap';

export const DISTINCT_VALUE_CALENDAR = 4;

export type AlgoliaEventsCalendarProps = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EventsCalendarProps<any>,
  'hits' | 'onLoadMore' | 'CalendarDaily' | 'Extension'
> & {
  transformHits?: (hits: EventHit<Date>[]) => OrNode<EventHit<Date>>[];
  transformHitsExtension?: (hits: EventHit<Date>[]) => OrNode<EventHit<Date>>[];
  initialDate?: Date;
  configureOptions?: UseConfigureProps;
  height?: string;
};

export const MOBILE_PAGES = 4;
export const DESKTOP_PAGES = 6;

const AlgoliaEventsCalendarUnmemoized = ({
  initialDate,
  configureOptions,
  transformHits,
  transformHitsExtension,
  height = '100%',
  Card,
  Wrapper,
  ...calendarProps
}: AlgoliaEventsCalendarProps) => {
  const [pivotDate, setPivotDate] = useState<Date>(initialDate || new Date());
  const pivotTime = pivotDate.getTime();
  const utcOffset = useUtcOffset();

  const isMobile = useMobile();

  const defaultPages = useMemo(() => {
    return isMobile ? MOBILE_PAGES : DESKTOP_PAGES;
  }, [isMobile]);

  const commonConfigureOptions = useMemo(() => {
    const configureOptionsDefaulted = configureOptions || {};
    return {
      ...configureOptionsDefaulted,
      hitsPerPage: configureOptionsDefaulted.hitsPerPage || defaultPages,
      numericFilters: [...(configureOptionsDefaulted.numericFilters || [])],
    };
  }, [configureOptions, defaultPages]);

  const RenderCalendarDaily = useCallback<
    FC<
      Pick<
        CalendarDailyProps,
        'dayToEvents' | 'onCalendarEndReached' | 'Extension' | 'query'
      >
    >
  >(
    (props) => {
      return (
        <CalendarDaily
          {...props}
          initialDate={new Date(pivotTime)}
          height={height}
          onDateSelect={setPivotDate}
        />
      );
    },
    [height, pivotTime],
  );

  const RenderCatalogWrapper = useCallback<
    AlgoliaLayoutBidirectionalProps['CatalogWrapperBidirectional']
  >(
    ({ hits, onLoadMore, Extension, query }) => {
      return (
        <EventsCalendar
          {...calendarProps}
          Card={Card}
          Wrapper={Wrapper}
          hits={hits}
          onLoadMore={onLoadMore}
          Extension={Extension}
          CalendarDaily={RenderCalendarDaily}
          query={query}
        />
      );
    },
    [Card, RenderCalendarDaily, Wrapper, calendarProps],
  );

  const configureOptionsWithPivotTime = useMemo(() => {
    const eventIndex = UTC_ALGOLIA_INDEX_NAME_MAP[Number(utcOffset)];
    const baseIndexName = eventIndex.replace(/_asc$/, '');

    const ascIndexName = `${baseIndexName}_asc`;
    const descIndexName = `${baseIndexName}_desc`;

    return {
      [`${ascIndexName}`]: {
        ...commonConfigureOptions,
        numericFilters: [
          ...commonConfigureOptions.numericFilters,
          `dateDay >= ${dateOnlyMillis(new Date(pivotTime))}`,
        ],
        distinct: DISTINCT_VALUE_CALENDAR,
      },
      [`${descIndexName}`]: {
        ...commonConfigureOptions,
        numericFilters: [
          ...commonConfigureOptions.numericFilters,
          `dateDay < ${dateOnlyMillis(new Date(pivotTime))}`,
        ],
        distinct: DISTINCT_VALUE_CALENDAR,
      },
    };
  }, [commonConfigureOptions, pivotTime, utcOffset]);

  return (
    <AlgoliaLayoutBidirectional
      transformHits={transformHits}
      transformHitsExtension={transformHitsExtension}
      CatalogWrapperBidirectional={RenderCatalogWrapper}
      configureOptions={configureOptionsWithPivotTime}
      Card={Card}
      Wrapper={Wrapper}
    />
  );
};

export const AlgoliaEventsCalendar = memo(AlgoliaEventsCalendarUnmemoized);
