import { ComponentProps, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { VerticalCarousel } from '../catalogs/VerticalCarousel';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { TOURNAMENT_CARD_DIMENSIONS } from '../../../../functions/src/config/ads/adDimensions';
import { KeyedNode, OrNode } from '../../../../functions/src/types/Hit';
import { CALENDAR_AD } from '../../../../functions/src/util/ads/adConstants';
import { TournamentCard } from '../../cards/tournament/TournamentCard';
import { SelectableEventWrapper } from '../../calendar/selectable-event/SelectableEventWrapper';
import { EventHit, RenderCard, RenderWrapper } from './EventsCalendar';
import { transformToEventKeyed } from '../../calendar/transformToEventsKeyed';

export const TOURNAMENT_CAROUSEL_AD_INTERVAL = 20 as const;
export const TOURNAMENT_AD_BORDER_RADIUS = '10px';

export type EventsVerticalWrapperProps = {
  hits: EventHit<Date>[];
  transformHits?: (hits: EventHit<Date>[]) => OrNode<EventHit<Date>>[];
  onLoadMore: () => void;
  Wrapper?: RenderWrapper<EventHit<Date>, Date>;
  Card?: RenderCard<EventHit<Date>, Date>;
  infiniteLoad?: boolean;
};

const EventsVerticalWrapperUnmemoized = ({
  hits,
  onLoadMore,
  transformHits,
  Card = TournamentCard,
  Wrapper = SelectableEventWrapper,
  infiniteLoad,
}: EventsVerticalWrapperProps) => {
  const hitsTransformed = useMemo(() => {
    return transformHits ? transformHits(hits) : hits;
  }, [hits, transformHits]);

  const content = useMemo(() => {
    return hitsTransformed
      .map((hitTransformed) => {
        if (
          hitTransformed &&
          typeof hitTransformed === 'object' &&
          'Node' in hitTransformed &&
          'key' in hitTransformed
        ) {
          return hitTransformed as KeyedNode;
        }
        if (hitTransformed && 'objectID' in hitTransformed) {
          return transformToEventKeyed(hitTransformed, Card, Wrapper);
        }
        return undefined;
      })
      .filter((item): item is KeyedNode => {
        return item !== undefined;
      });
  }, [Card, Wrapper, hitsTransformed]);

  return (
    <VerticalCarousel
      Content={content}
      onNearEnd={onLoadMore}
      background={5}
      cardSpacing="16px"
      showDivider={false}
      infiniteLoad={infiniteLoad}
    />
  );
};

export const EventsVerticalWrapper = memo(EventsVerticalWrapperUnmemoized);

export const EventsVerticalWrapperAds = withAdInjection({
  WrappedComponent: EventsVerticalWrapper,
  adInterval: TOURNAMENT_CAROUSEL_AD_INTERVAL,
  width: TOURNAMENT_CARD_DIMENSIONS.width,
  height: TOURNAMENT_CARD_DIMENSIONS.height,
  baseContainerId: CALENDAR_AD,
  adjustable: TOURNAMENT_CARD_DIMENSIONS.adjustable,
  borderRadius: TOURNAMENT_AD_BORDER_RADIUS,
});

export type CalendarExtensionVerticalAdsProps = ComponentProps<
  typeof EventsVerticalWrapperAds
>;
